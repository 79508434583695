<template>
  <div>
    <div class="box_heade">
      <div class="out_login" @click="outLogin">退出登录</div>
      <div class="touxiang">
        <img src="@/assets/images/touxiang.png" alt="" />
      </div>
      <!-- 已经认证 -->
      <div class="jibenzhil">
        <div class="shoujihao" v-if="mobileFlag">{{ mobile }}</div>
        <div class="rfvdc">
          <span>信贷员</span>
          <span class="xdy" v-if="realNameFlag">{{ realName }}</span>
          <span
            class="xdy"
            v-else
            v-html="placeholder"
            @click="autonymAt"
          ></span>
        </div>
      </div>
      <!-- 未认证 -->
      <!-- <div class="jibenzhil" v-html="placeholder"></div> -->
    </div>

    <div class="balanceecd">
      <div class="leiji">
        <div class="ketixn">总金额(元)</div>
        <div class="leiji_money">{{ balance }}</div>
      </div>
      <div class="ktxye">
        <div class="ktxye_money">
          {{ availableAmount }}
        </div>

        <div>可提现金额(元)</div>
      </div>
      <div class="ytxye">
        <div class="ytxye_money">
          {{ freezeAmount }}
        </div>

        <div>未结算金额(元)</div>
      </div>
    </div>
    <van-divider />
    <div class="leixing_box">
      <div class="leixing" @click="autonymAt">
        <img class="her_img" src="@/assets/images/shiming.png" alt="" />
        <div class="name">实名认证</div>
      </div>

      <div class="leixing" @click="withhold">
        <img class="her_img" src="@/assets/images/daikou.png" alt="" />
        <div class="name">我的订单</div>
      </div>

      <div class="leixing" @click="deposit">
        <img class="her_img" src="@/assets/images/tixian.png" alt="" />
        <div class="name">提现</div>
      </div>
     <div class="leixing" @click="spread">
        <img class="her_img" src="@/assets/images/tuiguang.png" alt="" />
        <div class="name">推广赚钱</div>
      </div>
      <div class="leixing" @click="subordinate">
        <img class="her_img" src="@/assets/images/sxj.png" alt="" />
        <div class="name">我的下级</div>
      </div>
      <!-- <div class="leixing" @click="explain">
        <img class="her_img" src="@/assets/images/shm.png" alt="" />
        <div class="name">推广说明</div>
      </div> -->
    </div>
    <div class="lise_box">
      <!-- <div class="bosc_list" @click="autonymAt">
        <img class="her_img" src="@/assets/images/shiming.png" alt="" />
        <div class="name">实名认证</div>
        <img class="foot_img" src="@/assets/images/yjt.png" alt="" />
      </div> -->
      <div class="bosc_list" @click="retrievePw">
        <img class="her_img" src="@/assets/images/xgmm.png" alt="" />
        <div class="name">修改密码</div>
        <img class="foot_img" src="@/assets/images/yjt.png" alt="" />
      </div>
      <div class="bosc_list" @click="czsm">
        <img class="her_img" src="@/assets/images/shuoming.png" alt="" />
        <div class="name">操作说明</div>
        <img class="foot_img" src="@/assets/images/yjt.png" alt="" />
      </div>

      <div class="bosc_list" v-if="waFalg">
        <a class="ergvfd" href="tel:400-6033-858">
          <img class="her_img" src="@/assets/images/kefu.png" alt="" />
          <div class="name">在线客服</div>
          <img class="foot_img" src="@/assets/images/yjt.png" alt="" />
        </a>
      </div>
      <div class="bosc_list gusn" v-if="waFalg" @click="gywm">
        <img class="her_img" src="@/assets/images/guanyu.png" alt="" />
        <div class="name">关于我们</div>
        <img class="foot_img" src="@/assets/images/yjt.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getRealAuth, getAccountInfo } from "@/request/api";
export default {
  data() {
    return {
      placeholder: "立即实名认证&nbsp;&nbsp;>",
      realName: "",
      realNameFlag: false,
      balance: "",
      freezeAmount: "",
      availableAmount: "",
      mobile: "",
      mobileFlag: false,
      waFalg: true,
    };
  },
  created() {
    this.getRA();
    this.getuserInfo();
  },
  methods: {
    //实名信息
    getRA() {
      getRealAuth().then((res) => {
        if (res.code == "200") {
          localStorage.setItem("withhold_realname", JSON.stringify(true));
          this.realName = res.data.name;
          this.realNameFlag = true;
          this.mobileFlag = true;
          let mobile = res.data.mobile;
          this.mobile =
            mobile.substring(0, 3) + "****" + mobile.substring(7, 11);
        } else {
          localStorage.removeItem("withhold_realname");
          this.realName = "";
          this.mobile = "";
          this.realNameFlag = false;
          this.mobileFlag = false;
        }
      });
    },

    // 获取当前登录用户金额信息
    getuserInfo() {
      getAccountInfo().then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.balance = res.data.balance;
          this.availableAmount = res.data.availableAmount;

          this.freezeAmount = res.data.freezeAmount;
        }
      });
    },
    // 退出登录
    outLogin() {
      // localStorage.removeItem("busDate");
      localStorage.removeItem("withhold_userData");
      localStorage.removeItem("withhold_tokens");
      this.$router.push("/login");
    },
    // 实名认证
    autonymAt() {
      this.$router.push("/autonymAt");
    },
    // 佣金代扣
    withhold() {
      let that = this;
      // 点击进行判断有没有实名认证
      getRealAuth().then((res) => {
        if (res.code == "200") {
          this.$router.push("/withholdList");
        } else {
          this.$dialog
            .alert({
              title: "实名认证",
              message: "您还未实名认证，是否立即去认证？",
              beforeClose,
              showConfirmButton: true, //确认按钮
              showCancelButton: true, //取消按钮
            })
            .catch(() => {});

          function beforeClose(action, done) {
            if (action === "confirm") {
              // 确认按钮
              that.$router.push("/autonymAt");
              done();
            } else {
              // 取消按钮
              done();
            }
          }
        }
      });
    },

    //  提现
    deposit() {
      this.$router.push("/deposit");
    },

    // 推广赚钱
    spread() {
      this.$router.push("/message");
    },
    // 我的下级
    subordinate() {
      this.$router.push("/subordinate");
    },
    // 推广说明
    explain() {
      this.$router.push("/explain");
    },

    // 修改密码
    retrievePw() {
      this.$router.push("/retrievePw");
    },
    // 关于我们
    gywm() {
      this.$router.push("/regardsWe");
    },
    // 操作说明
    czsm() {
      this.$router.push("/operatingI");
    },
  },
};
</script>

<style lang="scss" scoped>
.box_heade {
  width: 100%;
  height: 6.8rem;
  background: #6699cc;
  .out_login {
    float: right;
    margin-top: 0.8rem;
    margin-right: 1rem;
    color: #ff9900;
    font-weight: 550;
  }
  .touxiang {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-top: 2.4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 2rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 2rem;
      vertical-align: middle;
    }
  }
  .jibenzhil {
    display: inline-block;
    vertical-align: middle;
    color: #ffffff;
    .shoujihao {
      margin-bottom: 0.4rem;
    }
    .rfvdc {
      font-size: 0.8rem;
      margin-top: 0.2rem;
      .xdy {
        margin-left: 0.5rem;
      }
    }
  }
}

.balanceecd {
  // width: 90%;
  padding: 1rem 1rem;
  background-color: #F6F6F6;
  margin: 0 auto;
 
  .leiji {
    text-align: center;
    font-size: 0.8rem;
    .leiji_money {
      color: #66ccff;
      font-size: 1.6rem;
      margin-top: 0.5rem;
    }

    .ketixn {
      font-size: 1rem;
    }
  }
  .ktxye {
    display: inline-block;
    margin-top: 0.8rem;
    font-size: 0.8rem;
    .ktxye_money {
      text-align: center;
      color: #66ccff;
      font-size: 1.4rem;
    }
  }
  .ytxye {
    display: inline-block;
    float: right;
    font-size: 0.8rem;
    margin-top: 0.8rem;
    .ytxye_money {
      text-align: center;
      color: red;
      font-size: 1.4rem;
    }
  }
}

.lise_box {
  width: 95%;
  // border: 0.1rem solid #cccccc;
  border-radius: 0.45rem;
  margin: 0 auto;
  margin-top: 2rem;
  // box-shadow: 0 0 1rem rgba(0, 0, 0, 0.06);
}
.bosc_list {
  height: 3rem;
  line-height: 3rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.01);
  .her_img {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    margin-right: 1rem;
    margin-left: 0.6rem;
  }
  .foot_img {
    width: 1.3rem;
    height: 1.3rem;
    float: right;
    margin-top: 0.7rem;
    margin-right: 1rem;
  }
  .name {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
  }
  .ergvfd {
    color: #000000;
  }
}
.gusn {
  margin-bottom: 4rem;
}
.leixing_box {
  display: flex;
  width: 95%;
  flex-direction: space-around;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.06);
  flex-wrap: wrap;
  margin: 0 auto;
  border-radius: 0.45rem;

  .leixing {
    display: flex;
    width: 25%;
    height: 5rem;
    // padding: .6rem .4rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0.4rem;
  }
  .her_img {
    width: 2rem;
    height: 2rem;
  }
  .name {
    margin-top: 0.4rem;
    font-size: 1rem;
  }
}
</style>>